'use strict'

const platformUtils = require('santa-platform-utils/src/viewer-platform-worker-api')
const _ = require('lodash')
const scriptsValidator = require('../scriptsValidator')
const workerUtils = require('../workerUtils')
const constants = require('../../constants/constants')
const scriptsHandler = require('../scriptHandler')
const loggingUtils = require('../../utils/loggingUtils')
const {bi, fedops, ACTION_NAMES} = loggingUtils
const platformServices = require('../../platformServices')
const {OPENED_EXPERIMENTS, CSRF_TOKEN, MEASURE_PERF} = require('../../constants/store')
const {measurePerformance} = require('../workerUtils')

function createBootstrapHandler({store, messageService, pubSubService}) {
    function freezeGlobals() {
        Object.freeze(WeakMap.prototype)
    }

    function importScripts(url, scriptName, {onSuccess, onFailure, onFinally} = {}) {
        const beforeLoad = Date.now()
        try {
            scriptsHandler.importScripts(url, null, scriptName, beforeLoad)
            if (_.isFunction(onSuccess)) {
                onSuccess()
            }
        } catch (err) {
            /*eslint-disable no-console*/
            console.error(`Failed to load script: ${scriptName}, url: ${url}`)
            bi.reportPlatformRenderError({
                duration: _.now() - beforeLoad,
                name: ACTION_NAMES.SCRIPT_LOAD_FAILED,
                details: JSON.stringify({
                    scriptName,
                    scriptUrl: url
                }),
                error: err.message
            })
            if (_.isFunction(onFailure)) {
                onFailure(err)
            }
        }
        if (_.isFunction(onFinally)) {
            onFinally()
        }
    }

    function initMonitoring(platformServicesAPI) {
        self.monitoringFactories = {
            biLoggerFactory: platformServicesAPI.biLoggerFactory(),
            fedOpsLoggerFactory: platformServicesAPI.fedOpsLoggerCreateFactoryMethod
        }
        self.monitoringServices = platformServicesAPI.monitoring
        require('@wix/wixcode-sdk/js/modules/targets/initMonitoring.es6')
    }

    function cleanUpGlobalSelf() {
        delete self.openExperiments
        delete self.monitoringServices
        delete self.monitoringFactories
    }

    function initSdkUtilities() {
        self.wix.__INTERNAL__.initUtilities({
            richTextUtils: platformUtils.richTextUtils,
            uriUtils: platformUtils.uriUtils,
            linkUtils: platformUtils.linkUtils,
            backgroundUtils: platformUtils.backgroundUtils,
            repeaterUtils: platformUtils.repeaterUtils,
            videoUrlsUtils: platformUtils.videoUrlsUtils,
            typeUtils: platformUtils.typeUtils,
            widgetUtils: platformUtils.widgetUtils,
            mediaSrcHandler: platformUtils.mediaSrcHandler,
            pubSubService,
            messageService
        })
    }

    function initSdkParameters(parameters) {
        self.wix.__INTERNAL__.initEnv(parameters)
    }

    function cleanUpGlobalSelfSomeMoreIfNeeded(isDebug) {
        if (!isDebug) {
            delete self.wix
            delete self.postMessage
        }
    }

    function loadSdk({parameters, namespacesSdkSource, externalComponentsSource, openExperiments, isDebug}) {
        const platformServicesAPI = platformServices.getApi()
        self.openExperiments = openExperiments

        initMonitoring(platformServicesAPI) // must happen before we load the sdk
        self.wix = require('@wix/wixcode-sdk/js/modules/targets/wixCode.es6')
        initSdkParameters(parameters)
        initSdkUtilities()
        cleanUpGlobalSelf()
        loadNamespacesSdk(namespacesSdkSource)
        loadExternalComponents(externalComponentsSource)

        const sdk = self.wix
        cleanUpGlobalSelfSomeMoreIfNeeded(isDebug)
        return sdk
    }

    function loadNamespacesSdk(sdkUrl) {
        importScripts(sdkUrl, 'wixcode-namespaces', {onFailure: workerUtils.throwError})
    }

    function loadwSpy(wSpyParam) {
        if (wSpyParam) {
            importScripts(constants.WSPY_LATEST_DSN, 'wspy.js', {
                onSuccess: () => {
                    self.wSpy = self.initWorkerHost && self.initWorkerHost({
                        settings: constants.wSpySettings,
                        wSpyParam
                    })
                },
                onFailure: workerUtils.throwError
            })
        }
    }

    function loadExternalComponents(sdkExternalComponentsUrl) {
        importScripts(sdkExternalComponentsUrl, 'wixcode-components', {onFailure: workerUtils.throwError})
    }

    function loadFedOpsAndBi({biSessionData, sdkParameters, wixCodeBase, isBolt, experiments, isDebug, pageId, isPopup}) {
        const {renderingEnv, viewMode} = sdkParameters
        const viewerName = isBolt ? 'bolt' : 'santa'
        const viewerVersion = wixCodeBase && _.head(wixCodeBase.match(/\d[\d.]*\d/))
        const isPreview = viewMode !== 'site'
        const biSession = _.assign(
            biSessionData,
            {
                pageId,
                isServerSide: renderingEnv === 'backend',
                viewerName,
                artifactVersion: `${viewerName}-${viewerVersion}`,
                viewMode,
                isPreview,
                isPopup
            }
        )

        const {getFedOpsPlatformLoggers, getBiLoggers, getBiLoggerFactoriesForApp} = platformServices
        const reportTrace = experiments.has('sv_reportTrace')
        const reportPlatformFedops = reportTrace ||
            !isPopup && !biSession.isCached && biSession.pageNumber === 1

        const biStoreData = {
            biSessionData: biSession,
            isDebug,
            isPreview,
            biSampleByRequestId: experiments.has('biSampleByRequestId'),
            fedopsNoSampling: experiments.has('fedopsNoSampling'),
            reportTrace,
            reportPlatformFedops
        }

        loggingUtils.init(biStoreData, messageService, {getFedOpsPlatformLoggers, getBiLoggers, getBiLoggerFactoriesForApp}, experiments)
    }

    return function handleBootstrap(messageData, appsStore) {
        const {bootstrapArguments, isBolt, pageId, isPopup} = messageData
        store.setValues(bootstrapArguments)

        const {
            namespacesSdkSource,
            externalComponentsSource,
            wixCodeNamespacesAndElementorySupportSource,
            sdkParameters,
            openExperiments,
            csrfToken,
            isDebug,
            biSessionData,
            wSpyParam,
            wixCodeBase
        } = bootstrapArguments

        if (!sdkParameters) {
            throw new Error(`Could not load user code: \`sdkParameters\` has an invalid value: ${sdkParameters}`)
        }

        const measure = measurePerformance()
        store.setValue(MEASURE_PERF, measure)
        measure.start('bootstrap')

        const experiments = new Set(openExperiments)
        store.setValue(OPENED_EXPERIMENTS, experiments)

        if (self.isPseudoWorker) {
            self.setTimeout = () => 0
            self.setInterval = () => 0
        }

        loadFedOpsAndBi({
            biSessionData,
            sdkParameters,
            wixCodeBase,
            isBolt: Boolean(isBolt),
            experiments,
            isDebug,
            pageId,
            isPopup
        })
        fedops.reportPlatformLoadStarted()

        freezeGlobals()

        importScripts(wixCodeNamespacesAndElementorySupportSource, 'wixCodeNamespacesAndElementorySupport')

        store.setValue(CSRF_TOKEN, csrfToken)
        const applications = JSON.parse(bootstrapArguments.applications)
        scriptsValidator.validate(applications, ['id', 'url'])

        loadwSpy(wSpyParam)
        const sdk = loadSdk({parameters: sdkParameters, namespacesSdkSource, externalComponentsSource, openExperiments, isDebug})
        workerUtils.importModules(applications, appsStore)
        messageService.sendBootstrapMessage()
        measure.end('bootstrap')
        return sdk
    }
}

module.exports = createBootstrapHandler
